import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'

import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import '../styles/global.css'

export const EventTemplate = (props) => {
  const {
    title,
    subtitle,
    artist,
    url,
    city,
    eventStartDate,
    time,
    useTime,
    description,
    guests,
    venue,
    image,
  } = props

  return (
    <Container>
      {image && (
        <Jumbotron
          className="mt-3"
          style={{
            backgroundImage: `url(${image})`,
          }}
        >
          <div className="jumbotron__title">
            <h2>{ title }</h2>
            { !!subtitle && <p className="subtitle">{subtitle}</p> }
          </div>
        </Jumbotron>
      )}
      {!image && (
        <Fragment>
          <h2 className="mt-3">{ title }</h2>
          { !!subtitle && <p className="subtitle-0">{subtitle}</p> }
        </Fragment>
      )}
      <h3 className={!image && `mt-5`}>
        { eventStartDate }
        { useTime && ` at ${time}` }
      </h3>
      <dl>
        { artist &&
          <Fragment>
            <dt>Artist</dt>
            <dd>
              { artist }
              { !!guests && ` with ${guests}` }
            </dd>
          </Fragment>
        }
        { (venue || city) &&
          <Fragment>
            <dt>Venue</dt>
            <dd>
              { venue }
              { !!venue && !!city && `, ` }
              { city }
            </dd>
          </Fragment>
        }
        { description &&
          <Fragment>
            <dt>Description</dt>
            <dd>{ description }</dd>
          </Fragment>
        }
      </dl>
      { url &&
        <Button href={ url } variant="outline-secondary">
          More information
        </Button>
      }
    </Container>
  )
}

EventTemplate.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  artist: PropTypes.string,
  url: PropTypes.string,
  city: PropTypes.string,
  eventStartDate: PropTypes.string,
  time: PropTypes.string,
  useTime: PropTypes.bool,
  description: PropTypes.string,
  guests: PropTypes.string,
  venue: PropTypes.string,
  image: PropTypes.string,
}

const Event = (props) => {
  const { markdownRemark: post } = props.data

  return (
    <Layout>
      <Helmet titleTemplate={ `${post.frontmatter.title} (${post.frontmatter.eventStartDate}) - %s` } />
      <EventTemplate
        id={ post.id }
        title={ post.frontmatter.title }
        subtitle={ post.frontmatter.subtitle }
        artist={ post.frontmatter.artist }
        url={ post.frontmatter.url }
        city={ post.frontmatter.city }
        eventStartDate={ post.frontmatter.eventStartDate }
        time={ post.frontmatter.time }
        useTime={ post.frontmatter.useTime }
        description={ post.frontmatter.description }
        guests={ post.frontmatter.guests }
        venue={ post.frontmatter.venue }
        image={ post.frontmatter.image }
      />
    </Layout>
  )
}

Event.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default Event

export const pageQuery = graphql`
  query EventByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        artist
        city
        date
        description
        eventStartDate: date(formatString: "MMMM D")
        guests
        time: date(formatString: "h:mm a")
        useTime
        venue
        image
        url
      }
      id
    }
  }
`
